<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">{{ $lang.labels.password }}</div>
      <h2 class="text-h3 text-lg-h2 mb-6">{{ $lang.labels.setPassword }}</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-row v-if="err || success" dense no-gutters class="px-3">
        <v-col v-if="err" cols="12">
          <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
        </v-col>
        <v-col v-if="success" cols="12">
          <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
        </v-col>
      </v-row>
      <v-card-text>
        <v-text-field
          v-model="formData.password"
          type="password"
          :label="$lang.labels.password"
          outlined
          hide-details
          @keyup.enter="submit()"
        ></v-text-field>
        <v-text-field
          v-model="formData.confirmPassword"
          class="mt-2"
          type="password"
          :label="$lang.labels.confirmPassword"
          outlined
          hide-details
          @keyup.enter="submit()"
        ></v-text-field>
        <v-btn
          block
          class="accent mt-4 color-accent-text"
          x-large
          :disabled="loadingLogin || tokenMissing"
          :loading="loadingLogin"
          @click="submit()"
        >{{ $lang.labels.setPassword }}</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import auth from '@/auth/index'
import {
  setUserPasswordUsingPOST as setUserPassword
} from '@/utils/api'

export default {
  data() {
    return {
      tokenMissing: false,
      loadingLogin: false,
      formData: {
        confirmPassword: '',
        password: '',
        registerToken: ''
      },
      err: '',
      success: ''
    }
  },
  mounted() {
  },
  created() {
    if (this.$route && this.$route.params && this.$route.params.token) {
      this.formData.registerToken = this.$route.params.token
    } else {
      this.err = this.$lang.errors.tokenMissing
      this.tokenMissing = true
    }
  },
  methods: {
    submit() {
      this.err = ''
      this.loadingLogin = true
      // Check if Password is the same
      if (this.formData.password.length === 0) {
        this.err = this.$lang.errors.missingPassword
        this.loadingLogin = false

        return
      }
      if (this.formData.password !== this.formData.confirmPassword) {
        this.err = this.$lang.errors.passwordNotSame
        this.loadingLogin = false

        return
      }

      // Authenticate user
      setUserPassword({ request: this.formData })
        .then((res) => {
          if (res.data.status === 'SUCCESS') {

            this.loadingLogin = false
            this.success = this.$lang.success.passwordSet

            setTimeout(() => {
              window.open('/','_self')
            }, 4000)

            return
          }
          this.loadingLogin = false
          this.err = res.data.statusText
        })
        .catch((err) => {
          console.log(err)
          this.err = this.$lang.errors.somethingWentWrong
          this.loadingLogin = false
        })

    }
  }
}
</script>
